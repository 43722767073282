
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";
// import PaymentDetails from "../components/PaymentDetails";

function ExhibitionOpportunities() {
	return (
		<InnerLayout>
			<div className="row d-flex flex-row">
				<div
					className="col-sm-12 border"
					data-aos="zoom-in"
					data-aos-delay="200"
				>
					<div className="section-title">
						<h2>Exhibition Opportunities</h2>
					</div>

					<div className="row content">
						<div className="col-lg-12">
							<p>
								The event provides an ideal platform to showcase your products and services to decision makers and policy makers, in the field of water & environment. Products of interest for exhibition include (listed in alphabetical order to avoid bias), but are not limited to:</p>
							<div className="row content">
								<div className="col-lg-6">
									<ul>
										<li>Automation & Sensors for Monitoring</li>
										<li>Decentralised Water Supply Systems</li>
										<li>Disaster Response and Management Technologies</li>
										<li>Early Warning Systems and Technologies</li>
										<li>Filtration Systems</li>
										<li>Flood Control Systems</li>
										<li>Geophysical Technologies & Equipment</li>
										<li>Hydrological Mapping Tools</li>
										<li>Irrigation Technologies</li>
										<li>Landslide Monitoring Equipment</li>
									</ul>
								</div>
								<div className="col-lg-6">
									<ul>
										<li>Leak Detection Technologies</li>
										<li>NRW (Non-Revenue Water) Reduction Technologies</li>
										<li>Resilient Infrastructure Materials</li>
										<li>Reuse, Recover & Recycle Technologies</li>
										<li>Stormwater Management Solutions</li>
										<li>Waste Management Systems</li>
										<li>Water Harvesting</li>
										<li>Water Pollution Monitoring Systems</li>
										<li>Water Purification Products and Systems</li>
									</ul>
								</div>
							</div>
							<br />
							<p>Various display opportunities are available, For more information contact at : +91 9818568825 OR write at <a href="mailto:praggya@aquafoundation.in">praggya@aquafoundation.in</a>, <a href="mailto:praggya.sharmaa.aqua@gmail.com">praggya.sharmaa.aqua@gmail.com</a></p>
							<h5>Book Your Booth Now</h5>
							<HummzForm form="jaYm8qzAPS2QVTATs4lijD" />
							<br />
							{/* <PaymentDetails /> */}
							<ContactDetails />
						</div>
					</div>
				</div>
			</div>
		</InnerLayout>
	);
}

export default ExhibitionOpportunities;