
import InnerLayout from "../components/InnerLayout";

function AFAwards() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Aqua Foundation's Excellence Awards</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <p>The Aqua Foundation's Excellence Awards are conferred annually to honour outstanding achievements in water management, environmental stewardship, dam safety, tunneling, and resilient infrastructure projects. Celebrated during the World Aqua Congress, these prestigious awards recognize individuals and organizations that have demonstrated exceptional innovation and leadership. Distinguished past recipients include Dr. M S Swaminathan, Dr. Bindeshwar Pathak, Dr. Sunita Narain, Shri Anupam Mishra, Dr. S Z Qasim, Shri Bhure Lal, Dr. Vandana Shiva, and Justice Swatantra Kumar. These leaders have set high standards in their respective fields, contributing significantly to water, environment and sustainable development. Their pioneering work not only advances the state of their professions but also serves as an inspiration for continuous improvement and excellence in managing our natural and built environments.</p>
              <p>Number of Awards: The number of awards shall be between 0 and 40.</p>
              <p>(Please mail at - <a href="mailto:praggya@waterawards.in">praggya@waterawards.in</a> or <a href="mailto:info@aquafoundation.in">info@aquafoundation.in</a> for nomination form & details).</p>
              <p><strong>* The last date for submitting Award application is 30th September, 2024</strong></p>
              <p>For more details please visit at - <a className="btn-learn-more" href="https://www.waterawards.in/" target="_blank" rel="noreferrer">www.waterawards.in</a></p>
              <br /><br />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default AFAwards;
