
import InnerLayout from "../components/InnerLayout";

function AboutWAC() {
  return (
    <InnerLayout>    
            <div className="row d-flex flex-row">
              <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
                <div className="section-title">
                  <h2>About XVI World Aqua Congress 2024</h2>
                </div>

                <div className="row content">
                  <div className="col-lg-12">
                    <h4>Leveraging Water for Peace</h4>
                    <p>Aqua Foundation’s World Aqua Congress (Conference & Exhibition) brings together water, environment and related professionals from around the world and offers new insights into how cutting-edge research, technological innovation and leading practices shape the major transformation in water management. The conference provides a unique opportunity to learn about the latest trends in best practices, innovative technologies and cutting-edge research. Connecting experts from across water and environment sectors, it deliberates on challenges of delivering clean water to growing populations, keeping rivers and water bodies clean & healthy, best practices for ground water exploration and management, industrial water management, enhancing water use efficiency, impact of climate change, policies and regulations, and acknowledging champions of water & environment. Experts, practitioners, decision-makers, business innovators and young professionals from a range of sectors and countries network, exchange ideas, foster new thinking and develop solutions to the most pressing water-related challenges of today. We believe water is key to our future prosperity, and that together, we can achieve a water wise world. The Congress presents the collective knowledge and know-how through leading keynote speakers, tech-talks by industry leaders, research paper presentations, poster sessions, showcasing latest technologies in display areas, and open discussions.</p>
                    <p>Aqua Foundation's XVI World Aqua Congress, set to convene in November 2024, is themed "Leveraging Water for Peace," reflecting a commitment to address global water security and cooperation through advanced management strategies. This year's congress will gather global experts across various sectors—urban planning, agriculture, energy, environmental protection—to delve into a wide range of topics including Integrated Water Resource Management, Urban Water Challenges, and the use of Emerging Technologies in sustainable water practices. This premier event stands as a dynamic platform for sharing insights on effective water utilization, international water diplomacy, and innovative technological advancements in the water sector. Discussions will extend to pressing issues like water scarcity influenced by climate change, transboundary water cooperation for peace, and equitable water distribution. Participants will include experts, policymakers, business leaders, and young professionals, coming together to exchange ideas, foster innovative solutions, and drive action towards a water-wise future. Through keynotes, tech talks, research presentations, and exhibitions of cutting-edge technologies, the congress aims to highlight the multifaceted value of water—encompassing environmental, social, and cultural dimensions—and inspire a united approach to preserving this invaluable resource amid increasing global challenges.</p>
                    <p>The conference is being organized along with exhibition on water technology. Various new technologies have emerged for efficient use of water, water management, wastewater treatment, distribution, desalination, recycling, re-use etc., and exhibition provides a platform for display of these technologies.</p>
                    
                  </div>
                </div>
              </div>
            </div>
  </InnerLayout>
  );
}

export default AboutWAC;
