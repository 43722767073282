
import InnerLayout from "../components/InnerLayout";

function AFMembership() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Aqua Foundation Membership</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <p>
                An individual or an organization can find the proper platform through the society
                to make their voice heard by the world. By becoming a member of the society an individual
            or an organization shall avail following benefits:</p>

              <ul>
                <li>An opportunity to fulfil Individual social obligations</li>
                <li>Focussed enhancement of professional and social credibility on global scale</li>
                <li>Roadmap on discovering self and creating awareness on importance of a self realized
                    society to serve humanity</li>
                <li>Reinvention of togetherness on this global platform for achieving common beneficial
                    goals</li>
                <li>Opportunity to become sustainable training professional in own areas of strength
                    and impart trainings to niche segment</li>
              </ul>

              <h4>Aqua Foundation Membership Category</h4>
              <p>
                There are two membership categories available:</p>
              <h5>1. Individual Members</h5>
              <p>
                Any individual irrespective of his/her educational background, professional background,
                age, caste, creed is welcome to become a member of the organization. All you need
                to have within yourself is the zeal to be genuinely concerned on issues regarding
                environment and humans. You should have a heart to feel the pain caused due to prevailing
                issues in the society, on the planet Earth and should be able to lend us hands for
                actually fixing up these issues in the most beneficial ways possible .</p>

              <h5>2. Corporate Members</h5>
              <p>
                Any organization irrespective of their industry type, sector type, specialization
                type, size type is welcome to become a member of the organization. All you need
                to have within yourself is the zeal to be genuinely concerned on issues regarding
                environment and humans. You should have a heart to feel the pain caused due to prevailing
                issues in the society, on the planet Earth and should be able to lend us hands for
                actually fixing up these issues in the most beneficial ways possible .</p>

              <h5>(A) National Applicants</h5>

              <table border="1" cellSpacing="0" cellPadding="8" width="100%">
                <tr>
                  <td width="10%" align="center" valign="top">
                    <strong>S No.</strong>
                  </td>
                  <td width="39%" valign="top">
                    <strong>Type</strong>
                  </td>
                  <td width="23%" align="center" valign="top">
                    <strong>Admission Fee (INR)</strong>
                  </td>
                  <td width="28%" align="center" valign="top">
                    <strong>Annual Fee (INR)</strong>
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    1
                    </td>
                  <td valign="top">
                    Corporate
                    </td>
                  <td align="center" valign="top">
                    2,00,000.00
                    </td>
                  <td align="center" valign="top">
                    50,000.00
                    </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    2
                    </td>
                  <td valign="top">
                    Individual
                    </td>
                  <td align="center" valign="top">
                    5,000.00
                    </td>
                  <td align="center" valign="top">
                    2,000.00
                    </td>
                </tr>
              </table>
              <br />
              <h5>(B) International Applicants</h5>
              <table border="1" cellSpacing="0" cellPadding="8" width="100%">
                <tr>
                  <td width="10%" align="center" valign="top">
                    <strong>S No.</strong>
                  </td>
                  <td width="39%" valign="top">
                    <strong>Type</strong>
                  </td>
                  <td width="23%" align="center" valign="top">
                    <strong>Admission Fee (USD)</strong>
                  </td>
                  <td width="28%" align="center" valign="top">
                    <strong>Annual Fee (USD)</strong>
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    1
                    </td>
                  <td valign="top">
                    Corporate
                    </td>
                  <td align="center" valign="top">
                    5,000.00
                    </td>
                  <td align="center" valign="top">
                    1,250.00
                    </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    2
                    </td>
                  <td valign="top">
                    Individual
                    </td>
                  <td align="center" valign="top">
                    125.00
                    </td>
                  <td align="center" valign="top">
                    50.00
                    </td>
                </tr>
              </table>
              <br /><br />
              <p><a className="btn-learn-more" href="http://www.aquafoundation.in/membership_form.aspx" target="_blank" rel="noreferrer">For membership registration, please click here</a></p>
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default AFMembership;
