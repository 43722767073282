
import InnerLayout from "../components/InnerLayout";

function CancellationPolicy() {
  return (
    <InnerLayout>    
            <div className="row d-flex flex-row">
              <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
                <div className="section-title">
                  <h2>Cancellation Policy</h2>
                </div>

                <div className="row content">
                  <div className="col-lg-12">
                    <p>
                      We do not generally provide cancellation or refund for events, workshops and courses paid for on the website. However you may contact us at <a href="mailto:info@aquafoundation.in">info@aquafoundation.in</a> for your particular case and we may consider a full or partial refund.</p>
                  </div>
                </div>
              </div>
            </div>
  </InnerLayout>
  );
}

export default CancellationPolicy;
