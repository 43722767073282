
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";

function Contact() {
  return (
    <InnerLayout>    
            <div className="row d-flex flex-row">
              <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                </div>

                <div className="row content">
                  <div className="col-lg-6">
				  	<p><strong>Praggya Sharmaa (Ms)</strong><br/>
						Organising Secretary<br/>
						Mobiles: +91-9818568825, +91-9873556395<br/>
						Emails: <a href="mailto:wac@worldaquacongress.org">wac@worldaquacongress.org</a>, <a href="mailto:info@aquafoundation.in">info@aquafoundation.in</a><br/>
					</p>
				</div>
				<div className="col-lg-6">
					<p><strong>Aqua Foundation</strong><br/>
						Registered Office:<br/>
						707, Eros Apartments<br/>
						56-Nehru Place<br/>
						New Delhi - 110019<br/>
						INDIA</p>
                  </div>
				  <div className="col-lg-12">
					  <HummzForm form="TkNh6XueKOEfZImVNPT5qc" title="Write To Us" successMessage={`<div><h1 class="text-dark">Thank you for your message.</h1><h3>We will revert back to you soon. For any urgent queries, please reach out to Ms. Praggya Sharmaa, Organising Secretary at +91-9818568825 or +91-9873556395</h3></div>`} />
				  </div>
                </div>
              </div>
            </div>
  </InnerLayout>
  );
}

export default Contact;
