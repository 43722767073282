
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";
import PaymentDetails from "../components/PaymentDetails";

function AwardsOpportunities() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Awards Opportunities</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <p>Join us as a key sponsor for the prestigious Aqua Foundation's Excellence Awards 2024, presented during the XVI World Aqua Congress (16 WAC). This esteemed event celebrates outstanding achievements in water, environment, dam safety, tunnelling, and infrastructure. Align your brand with innovation and excellence in these critical sectors and gain unparalleled exposure to industry leaders, policy makers, and influential stakeholders from around the globe.</p>

              <ol>
                <li><strong> Presenting Sponsor - &ldquo;ABC Company&rdquo; Presents AF&rsquo;s Excellence Awards 2024</strong></li>
              </ol>
              <ul>
                <li><strong>Investment: INR 15,00,000</strong></li>
                <li><strong>Benefits &amp; Entitlements:</strong></li>
                <ul>
                  <li>Exclusive naming rights: &ldquo;ABC Company&rdquo; Presents AF&rsquo;s Excellence Awards 2024.</li>
                  <li>Prime logo placement in all event materials, including entry banners, backdrop, promotional videos, and event website.</li>
                  <li>Full-page advertisement in the congress proceedings.</li>
                  <li>Special recognition during the inaugural and concluding ceremonies.</li>
                  <li>Opportunity to deliver a welcome note at the award ceremony.</li>
                  <li>Two premium exhibition spaces in prime locations.</li>
                  <li>10 VIP passes to the World Aqua Congress.</li>
                  <li>Exclusive meet and greet with keynote speakers and award recipients.</li>
                  <li>Feature in pre-event marketing campaigns and press releases.</li>
                  <li>Dedicated post on social media channels and event website homepage.</li>
                  <li>Inclusion in all media coverage as the main sponsor.</li>
                </ul>
              </ul>
              <ol start="2">
                <li><strong> Platinum Sponsor</strong></li>
              </ol>
              <ul>
                <li><strong>Investment: INR 10,00,000</strong></li>
                <li><strong>Benefits &amp; Entitlements:</strong></li>
                <ul>
                  <li>Logo placement on event materials such as banners, backdrop, and promotional videos.</li>
                  <li>Half-page advertisement in the congress proceedings.</li>
                  <li>Acknowledgment during the award ceremony.</li>
                  <li>One premium exhibition space.</li>
                  <li>8 VIP passes to the World Aqua Congress.</li>
                  <li>Opportunity to participate in the panel discussions.</li>
                  <li>Posts on social media channels and inclusion on the event website.</li>
                  <li>Mention in press releases and media coverage.</li>
                </ul>
              </ul>
              <ol start="3">
                <li><strong> Gold Sponsor</strong></li>
              </ol>
              <ul>
                <li><strong>Investment: INR 5,00,000</strong></li>
                <li><strong>Benefits &amp; Entitlements:</strong></li>
                <ul>
                  <li>Logo placement on select event materials such as banners and the event program.</li>
                  <li>Quarter-page advertisement in the congress proceedings.</li>
                  <li>Acknowledgment during the award ceremony.</li>
                  <li>5 VIP passes to the World Aqua Congress.</li>
                  <li>Social media mentions and listing on the event website.</li>
                  <li>Inclusion in the event newsletter.</li>
                </ul>
              </ul>
              <p><strong>Why Sponsor?</strong></p>
              <ul>
                <li><strong>Visibility:</strong> Elevate your brand&rsquo;s profile among a targeted audience of industry professionals and decision-makers.</li>
                <li><strong>Networking:</strong> Engage with experts and leaders in the field, opening doors to new business opportunities and collaborations.</li>
                <li><strong>Leadership:</strong> Position your company as a thought leader in the sectors of water, environment, dam safety, tunnelling, and infrastructure.</li>
                <li><strong>Impact:</strong> Demonstrate your commitment to supporting and advancing sustainable and innovative practices in crucial development sectors.</li>
              </ul>
              <PaymentDetails />
              <ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default AwardsOpportunities;