
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";
import PaymentDetails from "../components/PaymentDetails";


function IndustryPresentation() {
	return (
		<InnerLayout>
			<div className="row d-flex flex-row">
				<div
					className="col-sm-12 border"
					data-aos="zoom-in"
					data-aos-delay="200"
				>
					<div className="section-title">
						<h2>Industry Presentation</h2>
					</div>

					<div className="row content">
						<div className="col-lg-12">
							<p>15 minutes special commercial / promotional slot is available to the industry to make presentations on their latest techniques, solutions, new discoveries, latest innovations, major break- through, etc. This is the opportunity to present your technology to policy makers, decision makers, stake holders, consultants and end users.</p>

							<table border="1" cellPadding="5" cellSpacing="0" align="center" className="w-50 my-2">
								<thead>
									<tr>
										<th>Category</th>
										<th>INR</th>
										<th>USD</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td align="center">Industry Slot</td>
										<td align="center">1,00,000</td>
										<td align="center">2,000</td>
									</tr>
								</tbody>
							</table>

							*GST 18% shall be applicable
							For more details please contact : Praggya Sharmaa at <a href="mailto:praggya@aquafoundation.in">praggya@aquafoundation.in</a> or <a href="mailto:praggya.sharmaa.aqua@gmail.com">praggya.sharmaa.aqua@gmail.com</a> or call +91 9818568825.
							
							<br /><br />
							<h5>Book Your Slot Now</h5>
							<HummzForm form="izKfa5eahH49uP5wdsDXsq" hasPayment={true} orderType={`product`} orderTypeId={"66c695e3592416c2aae66245"} />
							<br />
							<PaymentDetails />
							<ContactDetails />

						</div>
					</div>
				</div>
			</div>
		</InnerLayout>
	);
}

export default IndustryPresentation;