import '../assets/css/style.css';
// import logoDrop from '../assets/images/logo-drop.png';
import logoAqua from '../assets/images/logo-aqua-foundation.png';

function HeaderHome() {
  return (

    <section id="hero" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 hero-img" data-aos="zoom-in" data-aos-delay="200">
            <img src='assets/images/home-left.png' className="img-fluid" alt="Home banner" />
          </div>
          {/* <div className="col-lg-3 order-1 order-lg-1 hero-img" data-aos="zoom-in" data-aos-delay="200">
            <img src={logoDrop} className="img-fluid " style={{ height: "100px !important", width: "70%" }} alt="Each Drop Counts" />
          </div> */}
          <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="200">
            <div className='d-flex flex-column justify-content-start align-items-center'>
              <h2 className='text-white mb-0'>Aqua Foundation’s</h2>
              <h1 className="mb-0">XVI World Aqua Congress - 2024</h1>
              <h3 className='mb-md-5'>International Conference &amp; Exhibition</h3>
              <h2 className=''>Theme: Leveraging Water for Peace</h2>
              <h2 className='text-white'>18<sup>th</sup> - 19<sup>th</sup> November 2024<br/>The Suryaa New Delhi</h2>
              {/* <div className="d-lg-flex">
                <a href="#about" className="btn-get-started scrollto">Get Started</a>
                <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox btn-watch-video" data-vbtype="video" data-autoplay="true"> Watch Video <i className="icofont-play-alt-2"></i></a>
              </div> */}
            </div>
            <div className='d-flex flex-column justify-content-end align-items-center'>
              <h6 className='text-white'>Organized by</h6>
              <img src={logoAqua} className="img-fluid" style={{ maxWidth: "150px" }} alt="Aqua Foundation" />
            </div>
          </div>
          {/* <div className="col-lg-3 order-1 order-lg-3 hero-img" data-aos="zoom-in" data-aos-delay="200">
            <img src={logoAqua} className="img-fluid" alt="Aqua Foundation" />
          </div> */}
        </div>
        <div className='d-none d-md-flex flex-column flex-md-row justify-content-between pt-1'>
            <a href="/assets/pdf/WAC-Brochure-2024.pdf" target="_blank" rel="noreferrer" className="btn-get-started d-flex align-items-center text-decoration-none text-center bg-success mr-2">Download Brochure</a>
            <a href="/conference-abstract-information" className="btn-get-started d-flex align-items-center text-decoration-none text-center bg-secondary mr-2">Abstract Information </a>
            <a href="https://www.waterawards.in/register.php" target="_blank" rel="noreferrer" className="btn-get-started d-flex align-items-center text-decoration-none text-center bg-warning mr-2">Nomination for Awards</a>
            <a href="/quick-registration" className="btn-get-started d-flex align-items-center text-decoration-none text-center">Quick Registration</a>
            {/* <button title="Register as Exhibitor" className="btn btn-warning d-block p-3 mt-3 mb-1 text-left text-white"><a href="/exhibition-opportunities" className="d-block text-white text-decoration-none">Register as Exhibitor</a></button>
            <button title="Register as Delegate" className="btn btn-warning d-block p-1 mb-1 text-left text-white" ><a href="/register-as-delegate" className="d-block text-white text-decoration-none"><img src="assets/images/btndelegate.png" alt="" height="36px" className="mr-2" /> Register as Delegate</a></button>
            <button title="Register as Author" className="btn btn-warning d-block py-2 mb-2 text-left text-white"><a href="/conference-abstract-information" className="d-block text-white text-decoration-none"><img src="assets/images/btnauthor.png" alt="" height="28px" className="mr-2" /> Register as Author</a></button> */}
          </div>
    </div>
    </section >
  );
}

export default HeaderHome;