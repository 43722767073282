
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import PaymentDetails from "../components/PaymentDetails";
import ContactDetails from "../components/ContactDetails";

function Registration() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Delegate Registration</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <h5>Delegate Registration</h5>
              
              <HummzForm form="zWRYROPL4xmHPytJ4zucAi" hasPayment={true} orderType={`event`} orderTypeId={"66c693f9c5b3c0c312892c5a"} />
              <br/>
							<PaymentDetails />
							<ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default Registration;
