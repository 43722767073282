
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";
import PaymentDetails from "../components/PaymentDetails";

function SponsorshipOpportunities() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Sponsorship &amp; Promotional Opportunities</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">

              <p>To make the optimum use of this platform, get benefitted from the sponsorship opportunities available and increase your brand value. Your logo will be featured in all promotional materials and on site signatures. There are many cost effective sponsorship packages that would suit your needs and budget.</p>
              <table border="1" cellPadding="5" cellSpacing="0" width="100%" style={{ fontSize: "0.8em" }}>
                <thead>
                  <tr>
                    <td colSpan="10" align="center" style={{ backgroundColor: "red", color: "white" }}><strong>Levels</strong></td>
                  </tr>
                  <tr>
                    <th rowSpan={2}>Entitlements</th>
                    <th>Platinum</th>
                    <th>Gold</th>
                    <th>Silver</th>
                    <th>Bronze</th>
                    <th>Kit</th>
                    <th>Proceedings</th>
                    <th>Theme</th>
                    <th>Lunch</th>
                    <th>Lanyard</th>
                  </tr>
                  <tr>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;10,00,000 / $ 20000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;5,00,000 / $ 10000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;3,00,000 / $ 6000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;2,00,000 / $ 4000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;2,40,000 / $ 3000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;5,00,000 / $ 10000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;2,00,000 / $ 4000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;3,00,000 / $ 6000</th>
                    <th style={{ backgroundColor: "lightblue", color: "black" }}>&#8377;1,00,000 / $ 2000</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Logo on website</td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Logo on backdrop</td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Complimentary Delegates</td>
                    <td align="center">7</td>
                    <td align="center">5</td>
                    <td align="center">3</td>
                    <td align="center">2</td>
                    <td align="center">3</td>
                    <td align="center">3</td>
                    <td align="center">2</td>
                    <td align="center">2</td>
                    <td align="center">1</td>
                  </tr>
                  <tr>
                    <td>Inserts in Delegate bag</td>
                    <td align="center">2 pages A5</td>
                    <td align="center">2 pages A5</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Exhibition Space</td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td align="center"><i className="ri ri-check-line ri-2x" /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Other</td>
                    <td align="center">Logo on delegate bag</td>
                    <td align="center"></td>
                    <td align="center"></td>
                    <td align="center"></td>
                    <td align="center">Logo on delegate bag</td>
                    <td align="center"></td>
                    <td align="center"></td>
                    <td align="center">Logo in Lunch break</td>
                    <td align="center">Logo at Lanyard</td>
                  </tr>
                </tbody>
              </table>
              <br />

              <PaymentDetails />
              <ContactDetails />
              <br />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default SponsorshipOpportunities;