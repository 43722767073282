import '../assets/css/style.css';
// import logoDrop from '../assets/images/logo-drop.png';

function ContactDetails() {
  return (

    <div >
        <h5 ><strong>Contact for more details</strong></h5>
        <table>
            <tbody>
                <tr>
                    <td colSpan="3">
                        <strong>Praggya Sharmaa (Ms) </strong>
                    </td>
                </tr>
                <tr>
                    <td width="7%">
                        Mobiles
                    </td>
                    <td width="2%" align="center">
                        :
                    </td>
                    <td width="91%">
                        +91 9818568825, +91 9873556395
                    </td>
                </tr>
                <tr>
                    <td>
                        Telefax
                    </td>
                    <td align="center">
                        :
                    </td>
                    <td>
                        +91-11-41318030
                    </td>
                </tr>
                <tr>
                    <td>
                        Email
                    </td>
                    <td align="center">
                        :
                    </td>
                    <td>
                        <a href="mailto:praggya@aquafoundation.in">praggya@aquafoundation.in</a>&nbsp; | &nbsp;
                        <a href="mailto:praggya.sharmaa.aqua@gmail.com">praggya.sharmaa.aqua@gmail.com</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        Website
                    </td>
                    <td align="center">
                        :
                    </td>
                    <td>
                        <a className="link" href="https://www.worldaquacongress.org/" target="_blank" rel="noreferrer">www.worldaquacongress.org
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
        </div>
	
  );
}

export default ContactDetails;