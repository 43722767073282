
import InnerLayout from "../components/InnerLayout";

function RegistrationCost() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Registration Costs And Entitelements</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <h5>Visitor Registration Costs</h5>
              <table border="1" cellPadding="5" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Delegate Type</th>
                    <th colspan="2">Early Bird Rates (1 delegate) (till 15th October, 2024)</th>
                    <th colspan="2">Standard Rates (1 delegate) (After 15th October, 2024)</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>INR</th>
                    <th>USD</th>
                    <th>INR</th>
                    <th>USD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Government / NGO / Academic Institution</td>
                    <td>6,000</td>
                    <td>200</td>
                    <td>8,000</td>
                    <td>250</td>
                  </tr>
                  <tr>
                    <td>Private / PSU / Board / Individual</td>
                    <td>12,000</td>
                    <td>400</td>
                    <td>15,000</td>
                    <td>500</td>
                  </tr>
                  <tr>
                    <td>Student and full-time research scholar</td>
                    <td>4,000</td>
                    <td>150</td>
                    <td>4,000</td>
                    <td>150</td>
                  </tr>
                  <tr>
                    <td>Accompanying Person (Spouse only)</td>
                    <td>4,000</td>
                    <td>150</td>
                    <td>4,000</td>
                    <td>150</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="5">
                      <p>Note:</p>
                      <ul>
                        <li>10% discount on group of 3 or more delegates nominated from a single organization is applicable.</li>
                        <li>No discounts for Students and Accompanying Persons (Spouse only).</li>
                        <li>GST 18% is applicable.</li>
                      </ul>
                      <p>Registration fee includes entrance to the conference & exhibition, conference kit, coffee/tea, lunch.</p>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <p><a className="btn-learn-more" href="/register-as-delegate">Click here to register as delegate</a></p>
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default RegistrationCost;
