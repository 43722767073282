
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";
import PaymentDetails from "../components/PaymentDetails";


function AdvertisingOpportunities() {
	return (
		<InnerLayout>
			<div className="row d-flex flex-row">
				<div
					className="col-sm-12 border"
					data-aos="zoom-in"
					data-aos-delay="200"
				>
					<div className="section-title">
						<h2>Advertising Opportunities</h2>
					</div>

					<div className="row content">
						<div className="col-lg-12">
							<p>XVI World Aqua Congress 2024 is pleased to present you with various Advertising opportunities. Advertisements are available in various sizes. The Advertisements shall be carried out in XVI World Aqua Congress 2024 Technical Proceedings having ISBN number. These Proceedings are given away to all participating Delegates which come from backgrounds like : Policy makers & planners, Academicians – Scientists & Young Researchers, Water/Oil & Gas/ Mining/ Renewable & Non- renewable Energy Sectors- Government & Private Professionals, Urban & Rural Planners, Civil Society Members, NGOs.</p>
							<p>The Proceedings are also sent to all important libraries and related Ministries and Government, Public & Private organizations. The reach of these Proceedings is both National & International.</p>
							<table border="1" cellPadding="5" cellSpacing="0" width="100%">
								<thead>
									<tr>
										<th>Category</th>
										<th>INR</th>
										<th>USD</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Back Cover- Full- Colour</td>
										<td align="center">2,00,000</td>
										<td align="center">4000</td>
									</tr>
									<tr>
										<td>Front Inside Cover</td>
										<td align="center">1,00,000</td>
										<td align="center">2000</td>
									</tr>
									<tr>
										<td>Back Inside Cover</td>
										<td align="center">1,00,000</td>
										<td align="center">2000</td>
									</tr>
									<tr>
										<td>Double Spread</td>
										<td align="center">75,000</td>
										<td align="center">1500</td>
									</tr>
									<tr>
										<td>Book Mark</td>
										<td align="center">50,000</td>
										<td align="center">1000</td>
									</tr>
									<tr>
										<td>Full Page – Colour</td>
										<td align="center">50,000</td>
										<td align="center">1000</td>
									</tr>
									<tr>
										<td>Half Page – Colour</td>
										<td align="center">40,000</td>
										<td align="center">800</td>
									</tr>
									<tr>
										<td>Full Page – B&W</td>
										<td align="center">35,000</td>
										<td align="center">700</td>
									</tr>
									<tr>
										<td>Half Page – B&W</td>
										<td align="center">30,000</td>
										<td align="center">600</td>
									</tr>
								</tbody>
							</table>

							<br />
							<p><strong>*GST 5% shall be levied</strong></p>
							<br /><br />

							<PaymentDetails />
							<ContactDetails />
						</div>
					</div>
				</div>
			</div>
		</InnerLayout>
	);
}

export default AdvertisingOpportunities;