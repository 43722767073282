import React from 'react';
import { Navbar, Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

function Navigation2() {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="light" light expand="md" fixed='top'>
      <NavbarBrand href="/" className='d-inline d-md-none'>WAC XIV</NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mx-auto" navbar>
          <NavItem>
            <NavLink href="/">Home</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              About WAC
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/about-world-aqua-congress">About XVI World Aqua Congress 2024</DropdownItem>
              <DropdownItem href="/about-aqua-foundation">About Aqua Foundation</DropdownItem>
              <DropdownItem href="/aqua-foundations-excellence-awards">Aqua Foundation's Excellence Awards</DropdownItem>
              <DropdownItem href="/aqua-foundation-academy">Aqua Foundation Academy</DropdownItem>
              <DropdownItem href="/angels-of-humanity">Angels of Humanity</DropdownItem>
              <DropdownItem href="/memberships">Memberships</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Conference
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/conference-themes-and-topics">Themes and Topics</DropdownItem>
              <DropdownItem href="/assets/pdf/WAC-Brochure-2024.pdf" target="_blank">Download Brochure</DropdownItem>
              <DropdownItem href="/quick-registration">Quick Registration</DropdownItem>
              <DropdownItem href="/conference-abstract-information">Abstract Information</DropdownItem>
              <DropdownItem href="/registration-cost">Registration Cost</DropdownItem>
              <DropdownItem href="/register-as-delegate">Register as Delegate</DropdownItem>
              <DropdownItem href="/conference-abstract-information">Register as Author</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <NavLink href="/exhibition-opportunities">Exhibition</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/industry-presentation">Industry Presentation</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Branding
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/awards-opportunities">For Awards</DropdownItem>
              <DropdownItem href="/sponsorship-opportunities">For Conference</DropdownItem>
              <DropdownItem href="/advertising-opportunities">For Advertisement</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <NavLink href="https://www.waterawards.in" target="_blank" rel="noreferrer">Awards</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/partners">Partners</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/contact-us">Contact</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Gallery
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="/xiii-wac-gallery">XIII World Aqua Congress 2019 Gallery</DropdownItem>
              <DropdownItem href="assets/pdf/13th-wac-event-folder-2019.pdf" target="_blank" rel="noreferrer">XIII World Aqua Congress Event Folder 2019</DropdownItem>
              <DropdownItem href="/xii-wac-gallery">XII World Aqua Congress 2018 Gallery</DropdownItem>
              <DropdownItem href="assets/pdf/12th-wac-event-folder-2018.pdf" target="_blank" rel="noreferrer">XII World Aqua Congress Event Folder 2018</DropdownItem>
              <DropdownItem href="/xi-wac-gallery">XI World Aqua Congress 2017 Gallery</DropdownItem>
              <DropdownItem href="assets/pdf/11th-wac-event-folder-2017.pdf" target="_blank" rel="noreferrer">XI World Aqua Congress Event Folder 2017</DropdownItem>
              <DropdownItem href="/10-wac-gallery">10<sup>th</sup> World Aqua Congress 2016 Gallery</DropdownItem>
              <DropdownItem href="assets/pdf/10th-wac-event-folder-2016.pdf" target="_blank" rel="noreferrer">10<sup>th</sup> World Aqua Congress Event Folder 2016</DropdownItem>
            </DropdownMenu>
		</UncontrolledDropdown>
		</Nav>
	  </Collapse>
	</Navbar>
  );
}

export default Navigation2;