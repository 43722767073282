import '../assets/css/style.css';


function PaymentDetails() {
  return (

    <div >
        <h5 className=""><strong>Payment</strong></h5>
        
                <p>
                    Kindly make Cheque / Draft in the Name of AQUA
                    FOUNDATION Payable at NEW DELHI, INDIA
                </p>
                <p>
                    <strong>
                        GST as applicable shall be levied. Currently this is 5% on advertisements and 18% on other services.
                    </strong>
                </p>

                <p>You may also make the payment online.</p>
                <p>
                    <strong>Name of the Bank:</strong> ICICI Bank
                    Ltd <br />
                    <strong>Address of the Bank:</strong> ICICI
                    Bank, 9 A, Phelps Building, Connaught Place, New
                    Delhi- 110001, India <br />
                    <strong>
                        Name of the Account holder:
                    </strong>{" "}
                    AQUA FOUNDATION <br />
                    <strong>A/C No:</strong> 000701260885 <br />
                    <strong>IFSC code:</strong> ICIC0000007 <br />
                    <strong>Swift code:</strong> ICICI NBB CTS
                </p>
        </div>
	
  );
}

export default PaymentDetails;