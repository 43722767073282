
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";

function AngelsOfHumanity() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Angels of Humanity</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <img src="assets/images/angels1.jpg" className="float-right ml-2 img-fluid" alt="Angels of Humanity" />
              <p>
                Children are the future citizens. Children also have the unique advantage of having
                ‘un-conditioned’ mind and have the potential to change the world around us. We believe
                that when one of us changes our behaviour, this has a ripple effect in our community,
                encouraging others to do the same. In this way, we all have the power to lead the
                world by building an active civic society. It starts with the simple acts of standing up and doing the right thing.</p>
              <p>
                What is so exciting about the concept of ‘Angel of Humanity’ is that it shows us
                how to do the right thing. It takes the ideals of humanity and turns them into practical
                guidelines that we can use in everything we do. The program contains the values
                and morals that we all need in order to move the world ahead in sustainable manner.
              </p>
              <p>
                The program is targeting children in particular because we believe that by instilling
                a strong moral foundation in our children, we reach a far larger audience as the
                message spreads to their families and communities. If our children have the right values, we can change the future.</p>
              <HummzForm form="IJLZsMNRpwJ4pzydrPX7eD" title="Enroll your child/children today with us for creating a better world for them." />
              <br />
              <ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default AngelsOfHumanity;
