import '../assets/css/style.css';
import logoDrop from '../assets/images/logo-drop.png';
import logoAqua from '../assets/images/logo-aqua-foundation.png';

function Header() {
  return (

	<section id="hero-inner" className="d-flex align-items-center">
    <div className="container">
      <div className="row">
	  	  <div className="col-lg-3 order-1 order-lg-1 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src={logoDrop} className="img-fluid " style={{height:"100px !important", width:"70%"}} alt="Each Drop Counts" />
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-2" data-aos="fade-up" data-aos-delay="200">
          <h1 className="mb-0">XVI World Aqua Congress - 2024</h1>
          <h3>International Conference &amp; Exhibition</h3>
          <h2>Theme: Leveraging Water for Peace</h2>
          <h3>18<sup>th</sup> - 19<sup>th</sup> November 2024 - The Suryaa New Delhi</h3>
          {/* <h2 style={{
            color: "#FFF",
            letterSpacing: ".15em",
            textShadow: "0 -1px 0 #fff, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9)"
          }}>Event Successfully Concluded</h2> */}

		      {/* <h3 className="mt-3 text-success">Conclued Successfully - XIV WAC 2020</h3> */}
          <div className="d-lg-flex">
            <a href="/quick-registration" className="btn-get-started">Quick Registration</a>
            {/* <a href="https://www.wac2020.in" className="venobox btn-watch-video" data-vbtype="video" data-autoplay="true"> Experience XIV WAC 2020 <i className="icofont-play-alt-2"></i></a> */}
          </div>
        </div>
        <div className="col-lg-3 order-1 order-lg-3 hero-img" data-aos="zoom-in" data-aos-delay="200">
          <img src={logoAqua} className="img-fluid" alt="Aqua Foundation" />
        </div>
      </div>
    </div>
  </section>
  );
}

export default Header;