import { Fragment } from "react";
import Footer from "./Footer";
// import Header from "./Header";
import '../assets/css/style.css';
import Navigation from "./Navigation2";

import HummzForm from "./HummzForm";
import HeaderHome from "./HeaderHome";


function Home() {
  return (
    <Fragment>
      <Navigation />
      <HeaderHome />
      <main id="main">
        <section id="banner" className="mt-0 pt-0">
          <div className="container">
            <div className="row d-flex flex-row">
              {/* <div className="col-md-9 col-sm-12 order-1 order-lg-1 d-flex flex-row justify-content-between" data-aos="zoom-in" data-aos-delay="200">
                <img src='assets/images/home-left.png' className="img-fluid" style={{width:"55%"}} alt="Home banner" />
                <img src='assets/images/home-right.jpg' className="img-fluid" style={{width:"45%"}} alt="XV WAC 2024" />
              </div> */}
              <div className="col-md-3 col-sm-12 order-1 order-lg-2 d-flex d-md-none flex-column align-items-stretch pt-1 mx-0 px-0">
                {/*changecontent */}
                <button title="Abstract Information" className="btn btn-primary d-block p-2 mb-1 text-left text-white"><a href="/conference-abstract-information" className="d-block text-white text-decoration-none"><img src="assets/images/btnabstract.png" alt="" height="36px" className="mr-2"/>  Abstract Information </a></button>
                <button title="Nomination for Awards" className="btn btn-primary d-block p-2 mb-1 text-left text-white"><a href="https://www.waterawards.in/register.php" target="_blank" rel="noreferrer" className="d-block text-white text-decoration-none"><img src="assets/images/btnaward.png" alt="" height="36px" className="mr-2"/>  Nomination for Awards</a></button>
                <button title="Register as Exhibitor" className="btn btn-primary d-block p-2 mb-1 text-left text-white"><a href="/exhibition-opportunities" className="d-block text-white text-decoration-none"><img src="assets/images/exhibitor.png" alt="" height="36px" className="mr-2"/>  Register as Exhibitor</a></button>
                <button title="Download Brochure" className="btn btn-success d-block p-2 mb-1 text-left text-white"><a href="/assets/pdf/WAC-Brochure-2024.pdf" target="_blank" rel="noreferrer" className="d-block text-white text-decoration-none"><img src="assets/images/btnpdf.png" alt="" height="36px" className="mr-2"/>  Download Brochure</a></button>
                <button title="Register as Delegate" className="btn btn-primary d-block p-2 mb-1 text-left text-white" ><a href="/register-as-delegate" className="d-block text-white text-decoration-none"><img src="assets/images/btndelegate.png" alt="" height="36px" className="mr-2"/> Register as Delegate</a></button>
                <button title="Register as Author" className="btn btn-primary d-block p-2 mb-2 text-left text-white"><a href="/conference-abstract-information" className="d-block text-white text-decoration-none"><img src="assets/images/btnauthor.png" alt="" height="36px" className="mr-2"/> Register as Author</a></button>
                {/*<button title="Get Participation Certificate" className="btn btn-primary d-block p-1 mb-1 text-left text-white" ><a href="/participation-certificate" className="d-block text-white text-decoration-none"><img src="assets/images/certificate.png" alt="" height="36px" className="mr-2"/> Participation Certificate</a></button>*/}
                {/*<button title="Get Congress Proceedings" className="btn btn-primary d-block p-1 mb-1 text-left text-white"><a href="/proceedings" className="d-block text-white text-decoration-none"><img src="assets/images/congress.png" alt="" height="36px" className="mr-2"/>  Congress Proceedings</a></button>*/}
                {/* <button title="Visit XIV WAC 2020" className="btn btn-primary d-block p-1 mb-1 text-left text-white"><a href="https://www.wac2020.in?utm_source=world-aqua-congress&utm_medium=web&utm_campaign=hummz-pages" target="_blank" rel="noreferrer" className="d-block text-white text-decoration-none"><img src="assets/images/conference.png" alt="" height="36px" className="mr-2"/>  Visit XIV WAC 2020</a></button> */}
              </div>
            </div>  
          </div>
        </section>
        
        <section id="about" className="about">
          <div className="container">
            <div className="row d-flex flex-row">
              <div className="col-md-8 col-sm-12 order-2 order-lg-1 border">
                <div className="section-title">
                  <h2>About XVI World Aqua Congress 2024</h2>
                  <h5 className="text-dark">Theme: Leveraging Water for Peace</h5>
                </div>

                <div className="row content">
                  <div className="col-lg-12">
                    <p>The Aqua Foundation's World Aqua Congress is an annual event that brings together global experts in water and environmental sectors to discuss cutting-edge research, innovative technologies, and best practices in water management. The XVI edition, themed "Leveraging Water for Peace," will take place in November 2024, focusing on global water security, sustainable practices, and international cooperation. The congress features keynote speeches, tech talks, research presentations, and exhibitions, offering a platform for professionals across various sectors to exchange ideas, develop solutions, and promote the efficient use and preservation of water resources.</p>
                    <a href="/about-world-aqua-congress" className="btn-learn-more">Read More</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 order-2 order-lg-2">
                <HummzForm form="iUFClsGAHMTsiRxPE5Zo0l" title="Signup To Receive Updates" customStyle="border bg-light p-2" successMessage={`<h5 class="text-center text-dark" >Thank you for subscribing to updates from the World Aqua Congress. We will keep you informed with the latest news, upcoming event details, and key industry insights.</h5>`} />
              </div>
            </div>
        </div>
    </section>
  </main>

    <Footer />
  </Fragment>
  );
}

export default Home;
