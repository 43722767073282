import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";

function QuickRegistration() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Quick Registration</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <h5>Quick Registration</h5>
              
              <HummzForm form="hNflfeOcdppsoASqh1IAoo" />
              <br/>
							<ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default QuickRegistration;
