
import InnerLayout from "../components/InnerLayout";

function AFAbout() {
  return (
    <InnerLayout>    
            <div className="row d-flex flex-row">
              <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
                <div className="section-title">
                  <h2>About Aqua Foundation</h2>
                </div>
                <div className="row content">

                  <div className="col-lg-12 px-5">
                  <h4 className="text-center"><strong>Aqua Foundation- Serving Earth &amp; Humanity by implementing intense philosophy of - Love All, Feed All, Serve All. </strong></h4></div>
                  
                </div>
                <div className="row content">

                  <div className="col-lg-12">
                    
                  <p>
                      Aqua Foundation is a Non-Governmental Organization. It was founded in year 1998.
                      The founding member and existing members of organization are committed to bring
                      positive change in society and are contributing selflessly to various environmental
                      and humanitarian causes.</p>
                  <p>
                      The organization is registered under societies act, and has compliance with all
                      rules and regulations. Organization holds valid PAN, 12AA, 80G and FCRA. The accounts
                      are regularly audited and meet all the statutory requirements. Organization has
                      submitted all the utilization certificates (UC) for all the grants received form
                      various organizations. The annual reports are regularly prepared and published on
                      the website of the organization.</p>
                  <p>
                      The activities of Aqua Foundation include knowledge creation, information dissemination,
                      research and education. The organization works through a diverse range of tools
                      and techniques like - conferences, seminars, training programs, on-line learning,
                      capacity building, community projects, school activities etc., with prime focus
                      on Sustainability of Humanity and Environment.</p>
                  <p>
                      The ever balanced, ever harmonized cycles &amp; arrangements of nature have been greatly
                      challenged and disturbed by humans in last century, which in turn pose a threat
                      to their own survival. The organization has intensified its efforts and actions
                      to reverse these damages and work towards a sustainable living, in oneness with
                      fellow humans, other living beings, plants and nature. The ultimate goal of the
                      organization is to ensure man &amp; nature's long-term future.
                  </p>
                  <p>
                      Aqua Foundation is organizing international conference 'World Aqua Congress' since
                      year 2007. The conference aims to bring various stakeholders i.e. policy makers,
                      researchers, academicians, industry, NGOs etc., on a common platform for meaningful
                      discussions &amp; deliberations and implementation &amp; execution. 'Aqua Excellence Awards'
                      are also key highlight of the 2 days international conference. These awards are
                      given to promote, encourage &amp; recognise innovations and contributions in the field
                      of environment &amp; humanity. </p>
                      <p><a className="btn-learn-more" href="https://aquafoundation.in" target="_blank" rel="noreferrer">Learn more at www.aquafoundation.in</a></p>
                    
                    <br/><br/>
                    <h4 className="text-center"><strong>Aqua Foundation Presents</strong></h4>
                    <br/><br/>
                          <h5><strong>Aqua Foundation's Excellence Awards</strong></h5>
                      <img alt="" className="float-left mr-2 img-fluid" src="assets/images/awards.png" />
                      <p>
                          The "Aqua Foundation's Excellence Awards" are the highest awards given by Aqua Foundation
                          to its members, stakeholders and contributors in recognition of their outstanding
                          achievements in the field of water, environment, energy, earth sciences, atmospheric
                          sciences, planetary sciences, pollution control and sustainability solutions. Aqua
                          Foundation honours, in each World Aqua Congress, individuals &amp; organizations who
                          have made a mark in their respective fields of expertise or have made a significant
                          contribution in the field of water, environment and humanity.</p><p><a className="btn-learn-more" href="https://www.waterawards.in/" target="_blank" rel="noreferrer">
                              Learn more at www.waterawards.in</a></p>

                    <br/><br/>
                      <h5><strong>Aqua Foundation Academy</strong></h5>
                      <img alt="" src="assets/images/afa.jpg" width="109" height="83" className="float-left mr-2 img-fluid" />
                      <p>
                          <strong>AFA</strong> is an entity of Aqua Foundation, registered under Societies
                          act in year 1998. AFA provides training &amp; knowledge sharing platform to decision
                          &amp; policy makers, working professionals, operating level personnel and aspiring students
                          willing to specialise in technical sector. The broad objectives of AFA are:</p>
                      <p>
                          To organise specialised workshops for senior level officers of government departments
                          (policy and decision makers) on latest technology developments, enabling them to
                          be benefited by latest international research and development. </p>
                          <p><a className="btn-learn-more" href="https://aquafoundation.in/about_afa.html" target="_blank" rel="noreferrer">
                          Learn more at www.aquafoundation.in</a>&nbsp;&nbsp;&nbsp;
                          {/* <a className="btn-learn-more" href="https://www.afacademy.info" target="_blank" rel="noreferrer">Learn more at www.afacademy.info</a> */}
                          <a className="btn-learn-more" href="https://www.afacademy.org" target="_blank" rel="noreferrer">
                          Learn more at www.afacademy.org</a></p>
                          

                      <br/><br/><h5><strong>Angels of Humanity</strong></h5>
                      <img alt="" className="float-left mr-2 img-fluid" src="assets/images/angels1.jpg" />
                      <p>
                          Children are the future citizens. Children also have the unique advantage of having
                          ‘un-conditioned’ mind and have the potential to change the world around us. We believe
                          that when one of us changes our behaviour, this has a ripple effect in our community,
                          encouraging others to do the same. In this way, we all have the power to lead the
                          world by building an active civic society. It starts with the simple acts of standing
                          up and doing the right thing.</p><p><a className="btn-learn-more" href="/angels-of-humanity">
                              Learn more about Angels of Humanity</a></p>
                        
                      <br/><br/><h5><strong>Memberships</strong></h5>
                      <img alt="" className="float-left mr-2 img-fluid" src="assets/images/memberships.png" />
                      <p>
                          <b>"Memberships"</b> an individual or an organization can find the proper platform
                          through the society to make his voice heard by the world. By becoming a member of
                          the society an individual or an organization can.</p><p><a className="btn-learn-more" href="/memberships">
                              Learn more about the membership</a></p> 
                  </div>
                </div>
              </div>
            </div>
  </InnerLayout>
  );
}

export default AFAbout;
