import React from 'react';
import { Form } from 'react-formio';
import axios from 'axios';
// import ContactDetails from './ContactDetails';

 
const BaseURL = 'https://api.hummz.net/v2';
// const BaseURL = 'http://localhost:3000/v2';
const prod = process.env.NODE_ENV === 'production';

export default class HummzForm extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			// context: props.context,
			formSlug: props.form || {},
			formSchema: props.schema || null,
			title: props.title || '',
			orderType: props.orderType || '',
			orderTypeId: props.orderTypeId || '',
			hasPayment: props.hasPayment || false,
			successMessage: props.successMessage || '<h1 class="text-center text-dark" >Thank you!</h1><h3 class="text-center text-dark">For any queries or additional information contact on details below.</h3>',
			errorMessage: props.errorMessage || '<h1 class="text-center text-dark">Unfortunately we were unable to process your submission.</h1><h3 class="text-center text-dark">Please contact at +91-9818568825; +91-9873556395 or write to us at wac@worldaquacongress.org; info@aquafoundation.in.</h3>',
			paymentFailureMessage: props.paymentFailureMessage || '<h1 class="text-center text-dark">Thank you for your interest!</h1><h3 class="text-center text-dark"> Unfortunately we were unable to process your payment. Please contact at +91-9818568825; +91-9873556395 or write to us at wac@worldaquacongress.org; info@aquafoundation.in to complete the payment.</h3>',
			paymentProcessingMessage: props.paymentProcessingMessage || '<h1 class="text-center text-dark">Thank you!</h1><h3 class="text-center text-dark">Thank you for registering. We shall confirm your participation upon receipt of payment. For payment details, queries or additional information please see details below.</h3>',
			customStyle: props.customStyle || '',
			submission: { data: { } },
			formSubmit: '',
			location: '',
		};
	}


	
	componentDidMount() {
		if (!this.state.formSchema) {
			axios.get(`${BaseURL}/forms/schema/${this.state.formSlug}`)
			.then( (response) => {
				// console.log(response);
				let formSchema = response.data;
				formSchema = JSON.parse(JSON.stringify(formSchema).replace('{{url}}', BaseURL));
				this.setState({ formSchema });
			})
			.catch ((error) => {
				console.error(error);
			})
		}

		// inject razorpay
		const script = document.createElement("script");
    	script.src = "https://checkout.razorpay.com/v1/checkout.js";
    	script.async = true;
		document.body.appendChild(script);
		
		this.getClientIp();
	}

	getClientIp = async () => {
		// get client ip address and save in state
		if (window.location.host.indexOf('localhost') < 0) {
			let ipresponse = await fetch("https://api.hummz.net/v2/countries/ip2location")
			let location = await ipresponse.json();
			// console.log(location);
			this.setState({location});
		} else {
			// console.log('Skipping ip address for localhost');
			
		}
	}

	buttonEvent = (event) => {
		// console.log(event);
	}

	onFormSubmit = (submission) => {
		// console.log(submission);
		let formData = {};
		formData.data = submission.data;
		formData.meta = {formSlug: this.state.formSlug};
		formData.meta.metadata = submission.metadata;
		formData.meta.metadata.ip = this.state.location.query;
		formData.meta.metadata.location = this.state.location;
		formData.meta.metadata.pathName = formData.meta.metadata.pathName || window.location.href || '';
		delete formData.data.submit;
		if (this.state.hasPayment) {
			formData.data.currency = formData.data.amount.substr(0,3);
			formData.data.amount = parseFloat(formData.data.amount.substr(3));
			formData.data.taxAmount = parseFloat(formData.data.taxAmount.substr(3));
			formData.data.totalAmount = parseFloat(formData.data.totalAmount.substr(3));
		}
		this.setState({formData});
		
		// console.log("hasPayment", this.state.hasPayment, formData.data.paymentMode);
		if (this.state.hasPayment) {
			// create order and handle payment
			this.createOrder();
		} else {
			// submit form to the server
			// console.log('The form was just submitted!!!', formData);
			
			axios( `${BaseURL}/forms/response/${this.state.formSlug}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				data: formData
			} )
			.then( ( response ) => {
				// console.log('resolving successfully', response.data);
				this.setState({formSubmit: 'success'})
				window.location.href = "#thankyou";
				
				//this.sectionFormModalToggle();
				// var t = document.getElementById("registration_form"),
				// 	n = document.getElementById("register-success-confirm");
				// if (formData.participation !== 'confirm') {
				// 	n = document.getElementById("register-success");
				// }
				// t.classList.add("d-none"), n.classList.remove("d-none");
			} )
			.catch( ( error ) => {
				console.error( error );
				this.setState({formSubmit: 'error'})
			} );
		}
	}

	createOrder = async () => {
		let productVariant = '';
		if (this.state.orderType === 'event') {
			if (this.state.formData.data.delegateType) {
				productVariant = this.state.formData.data.delegateType;
			}
		}
		// Check if the order already has an orderId
		// If yes, sent the same to retry the payment
		let order = {};
		// console.log("createorder -> formdata", this.state.formData);
		order.issueDate = (new Date()).toISOString();
		order.type = this.state.orderType;
		order.customer = {
			title: this.state.formData.data.title,
			firstname: this.state.formData.data.firstname,
			middlename: this.state.formData.data.middlename,
			lastname: this.state.formData.data.lastname,
			name: this.state.formData.data.title + ' ' + this.state.formData.data.firstname + ' ' + this.state.formData.data.middlename + ' ' + this.state.formData.data.lastname,
			email: this.state.formData.data.email,
			mobile: this.state.formData.data.mobile,
			address1: this.state.formData.data.address1 || '',
			address2: this.state.formData.data.address2 || '',
			city: this.state.formData.data.city || '',
			state: this.state.formData.data.state || '',
			postalcode: this.state.formData.data.postalcode || '',
			countryId: this.state.formData.data.countryId
		};
		order.items = [
			{
				sku: '',
				name: this.state.formSchema.title,
				hsnSac: '',
				quantity: 1,
				currency: this.state.formData.data.currency,
				unitPrice: this.state.formData.data.amount,
				unit: 'no',
				discount_percent: 0,
				discount_amount: 0,
				tax_percent: ((this.state.formData.data.taxAmount / this.state.formData.data.amount) * 100),
				tax_amount: this.state.formData.data.taxAmount,
				variant: productVariant,
				meta: {}
			}
		];
		order.totalQuantity = 1;
		order.currency = order.items[0].currency;
		order.totalDiscountAmount = 0;
		order.totalTaxAmount = order.items[0].tax_amount;
		order.totalAmount = order.items[0].unitPrice;
		order.totalNetAmount = order.totalAmount + order.totalTaxAmount;
		order.totalTaxableAmount = order.totalAmount;
		order.isSubscription = false;
		order.orderableId = this.state.orderTypeId;
		order.orderableType = this.state.orderType;
		order.subscriptionFrequency = '';
		order.subscriptionCount = 0;
		order.gst = {
		}
		order.meta = {
			metadata: this.state.formData.metadata,
			formData: this.state.formData.data,
			formSlug: this.state.formSlug
		}

		if (this.state.formData.data.paymentMode !== 'online') {
			order.status = 'processing';
		}

		axios( `${BaseURL}/orders/payment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			data: order
		} )
		.then( ( response ) => {
			// console.log(response);
			let responseJson = response.data;
			// console.log('resolving successfully', responseJson);
			let orderId = responseJson.documentNumber;
			let orderRef = responseJson.orderReference[0].reference;
			order.orderId = orderId;
			order.orderRef = orderRef;
			this.setState({order: order});
			if (this.state.formData.data.paymentMode === 'online') {
				this.handlePayment(orderId, orderRef);
			} else {
				this.setState({paymentSuccessful: false, order: {}, formSubmit: 'processing'});
			}
			
		} )
		.catch( ( error ) => {
			console.error( error );
		} );
	}

	handlePayment = (orderId, orederRef) => {
		let options = {
            key: prod ? "rzp_live_DaBZwF5h0IFzfR" : "rzp_test_EfnGW77z6PMPCo",
			amount: this.state.order.totalNetAmount * 100,
			currency: this.state.order.currency,
			order_id: orederRef,
			name: "Aqua Foundation",
			description: this.state.order.items[0].title,
			image: "https://cdn.humz.it/aquafoundation/afa-logo.png",
			handler: (response) => {
                // formData.meta.paymentId = response.razorpay_payment_id;
                // console.log('Payment received', response);
                if (orderId && orderId.length) {
                    axios( BaseURL + '/orders/payment/' + orderId, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {paymentDetail: response}
                    } )
                    .then( ( response ) => {
                        // console.log('updated order', response.data);
                    } )
                    .catch( ( error ) => {
                        console.error( error );
                    } );
					this.setState({paymentSuccessful: true, order: {}, formSubmit: 'success'});
				
                }
			},
            modal: {
                ondismiss: () => {
                    // console.log('Checkout form closed');
					this.setState({paymentSuccessful: false, formSubmit: 'aborted'});
					axios( BaseURL + '/orders/payment/' + orderId, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data: {paymentDetail: {}, status: 'aborted'}
                    } )
                    .then( ( response ) => {
                        // console.log('updated order', response.data);
                    } )
                    .catch( ( error ) => {
                        console.error( error );
                    } );
                }
            },
			prefill: {
				name: this.state.formData.data.firstname + ' ' + this.state.formData.data.middlename + ' ' + this.state.formData.data.lastname,
				email: this.state.formData.data.email,
				contact: this.state.formData.data.mobile,
			},
			notes: {
				slug: this.state.formSlug,
				type: this.state.orderType,
				name: this.state.formData.data.firstname + ' ' + this.state.formData.data.middlename + ' ' + this.state.formData.data.lastname,
				email: this.state.formData.data.email,
				phone: this.state.formData.data.mobile,
                country: this.state.formData.data.country
			},
			theme: {
				color: "#c4aea2"
			}
		};
		// console.log('Options', options);
		let rzp1 = new window.Razorpay(options);
		rzp1.open();
	}


	render() {
		return (
			<div className={this.state.customStyle}>
				<h4>{this.state.title}</h4>
				{(this.state.formSchema && this.state.formSubmit === '') &&
					<Form form={this.state.formSchema} options={{ noAlerts: true, readOnly: false }} submission={this.state.submission} onCustomEvent={this.buttonEvent} onSubmit={this.onFormSubmit} />
				}
				<p id="thankyou"></p>
				{this.state.formSubmit === 'success' && 
					<div dangerouslySetInnerHTML={{__html:this.state.successMessage}} className="bg-light shadow p-5 font-weight-bold">
					</div>
				}
				{this.state.formSubmit === 'processing' &&
					<div dangerouslySetInnerHTML={{__html:this.state.paymentProcessingMessage}} className="bg-light shadow p-5 font-weight-bold">
					</div>
				}
				{this.state.formSubmit === 'error' && 
					<div dangerouslySetInnerHTML={{__html:this.state.errorMessage}} className="bg-light shadow p-5 font-weight-bold">
					</div>
				}
				{this.state.formSubmit === 'aborted' && 
					<div dangerouslySetInnerHTML={{__html:this.state.paymentFailureMessage}} className="bg-light shadow p-5 font-weight-bold">
					</div>
				}
			</div>
		)
	}
	
}
