
import InnerLayout from "../components/InnerLayout";

function AFAcademy() {
  return (
    <InnerLayout>    
            <div className="row d-flex flex-row">
              <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
                <div className="section-title">
                  <h2>Aqua Foundation Academy</h2>
                </div>

                <div className="row content">
                  <div className="col-lg-12">
                    <p><strong>Aqua Foundation Academy</strong> is an entity of Aqua Foundation, registered under Societies act in year courses at Diploma/ PG Diploma and Certificate levels in the field of Water and Land Surveying .Specialized trainings and workshops based on the Industry needs are also been organized.</p>
                    <p><a className="btn-learn-more" href="https://aquafoundation.in/about_afa.html" target="_blank" rel="noreferrer">www.aquafoundation.in</a> / <a className="btn-learn-more" href="http://www.afacademy.org/" target="_blank" rel="noreferrer">www.afacademy.org</a></p>
                  </div>
                </div>
              </div>
            </div>
  </InnerLayout>
  );
}

export default AFAcademy;
