import InnerLayout from "../components/InnerLayout";
// import ImageGallery from 'react-image-gallery';

// import gallaries from '../data'
const GalleryView = (props) => {
  // const gallery = gallaries[`${props.match.params.galleryId}`];
  // console.log(gallery);
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            {/* <h2>{gallery.title}</h2> */}
          </div>
          
          <div className="row content">
            <div className="col-sm-12">
              <p>lorem</p>
              {/* <ImageGallery items={gallery.contents} /> */}
            </div>
          </div>
        </div>
      </div>
    </InnerLayout> 
  );
}

export default GalleryView;
