
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";

function AbstractInformation() {
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>Abstract Information</h2>
          </div>

          <div className="row content">
            <div className="col-lg-12">
              <img alt="" className="float-right img-fluid" src="assets/images/call_for_abstract.png" />
              <p>
                  An abstract of no more than 500 words is to be submitted containing complete information
                  on the following :
              </p>
              <ul>
                  <li>Proposed title of less than 15 words.</li>
                  <li>Full name of author and co-authors.</li>
                  <li>Full contact address of corresponding author (including telephone, fax and e-mail).</li>
              </ul>
              <ul>
                  <li>Identification of the Congress theme most closely related to the paper. After confirming
                      the acceptance of the presentation, authors will be required to send a complete
                      version of the paper that will be included in the proceedings volume / CD ROM of
                      the Congress.</li>
                  <li>Five keywords of the paper. </li>
              </ul>
              <h5>Submit Abstract</h5>
              <p>You can submit the Online Abstract using the form below or email it to <a href="mailto:info@aquafoundation.in">info@aquafoundation.in</a></p>
              <p><strong>The last date for submitting abstracts is 30th September, 2024</strong></p>{/*changecontent */}
              
              <HummzForm form="z9LZHB7zOSTV6ItJNg2Thp" successMessage={`<h1 class="text-center text-dark">Thank you for your submission!</h1><h3 class="text-center text-dark">For any queries or additional information contact on details below.</h3>`}/>
              <br />
              <ContactDetails />
            </div>
          </div>
        </div>
      </div>
    </InnerLayout>
  );
}

export default AbstractInformation;
