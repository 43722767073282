import InnerLayout from "../components/InnerLayout";
import gallaries from '../data'
const Gallery = (props) => {
  const gallery = gallaries[`g${props.gallery}`];
  
  return (
    <InnerLayout>
      <div className="row d-flex flex-row">
        <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
          <div className="section-title">
            <h2>{gallery.title}</h2>
          </div>
          
          <div className="row content d-flex flex-row justify-content-center">
            {gallery.contents.map((g) => {
            return (
              <div className="col-lg-5 bg-white border text-center m-2" key={`gallery-${g.url}`}><a href={`/gallery/g${props.gallery}_${g.url}`} className="stretch-link text-decoration-none"><h5>{g.heading}</h5><br/><img src={g.image} alt={g.heading} /><br/>View Gallery</a></div>
            )
          })}    
          </div>
        </div>
      </div>
    </InnerLayout> 
  );
}

export default Gallery;
