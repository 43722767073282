
import HummzForm from "../components/HummzForm";
import InnerLayout from "../components/InnerLayout";
import ContactDetails from "../components/ContactDetails";


function Proceedings() {
  return (
    <InnerLayout>    
            <div className="row d-flex flex-row">
              <div className="col-sm-12 border" data-aos="zoom-in" data-aos-delay="200">
                <div className="section-title">
                  <h2>Proceedings</h2>
                </div>

                <div className="row content">
					<div className="col-lg-12">
					  <HummzForm form="ZmYobkj32iFnWkIFwQG1Ju" hasPayment={true} orderType={`product`} />
				  	</div>
					
					  <div className="col-lg-12">
					  <ContactDetails />
					  <br />
					</div>
                </div>
              </div>
            </div>
  </InnerLayout>
  );
}

export default Proceedings;
