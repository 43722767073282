const g12_9 = require("./12-9.json");
const g10_0 = require("./10-0.json");
const g10_1 = require("./10-1.json");
const g10_2 = require("./10-2.json");
const g10_3 = require("./10-3.json");
const g10_4 = require("./10-4.json");
const g10_5 = require("./10-5.json");
const g10_6 = require("./10-6.json");
const g10_7 = require("./10-7.json");
const g10_8 = require("./10-8.json");
const g10_9 = require("./10-9.json");
const g10_10 = require("./10-10.json");
const g10_11 = require("./10-11.json");
const g10 = require("./10.json");
const g11_0 = require("./11-0.json");
const g11_1 = require("./11-1.json");
const g11_2 = require("./11-2.json");
const g11_3 = require("./11-3.json");
const g11_4 = require("./11-4.json");
const g11_5 = require("./11-5.json");
const g11_6 = require("./11-6.json");
const g11_7 = require("./11-7.json");
const g11 = require("./11.json");
const g12_0 = require("./12-0.json");
const g12_1 = require("./12-1.json");
const g12_2 = require("./12-2.json");
const g12_3 = require("./12-3.json");
const g12_4 = require("./12-4.json");
const g12_5 = require("./12-5.json");
const g12_6 = require("./12-6.json");
const g12_7 = require("./12-7.json");
const g12_8 = require("./12-8.json");
const g12 = require("./12.json");
const g13_0 = require("./13-0.json");
const g13_1 = require("./13-1.json");
const g13_2 = require("./13-2.json");
const g13_3 = require("./13-3.json");
const g13_4 = require("./13-4.json");
const g13_5 = require("./13-5.json");
const g13_6 = require("./13-6.json");
const g13_7 = require("./13-7.json");
const g13 = require("./13.json");

const gallery = {
	g12_9: g12_9,
	g10_0: g10_0,
	g10_1: g10_1,
	g10_2: g10_2,
	g10_3: g10_3,
	g10_4: g10_4,
	g10_5: g10_5,
	g10_6: g10_6,
	g10_7: g10_7,
	g10_8: g10_8,
	g10_9: g10_9,
	g10_10: g10_10,
	g10_11: g10_11,
	g10: g10,
	g11_0: g11_0,
	g11_1: g11_1,
	g11_2: g11_2,
	g11_3: g11_3,
	g11_4: g11_4,
	g11_5: g11_5,
	g11_6: g11_6,
	g11_7: g11_7,
	g11: g11,
	g12_0: g12_0,
	g12_1: g12_1,
	g12_2: g12_2,
	g12_3: g12_3,
	g12_4: g12_4,
	g12_5: g12_5,
	g12_6: g12_6,
	g12_7: g12_7,
	g12_8: g12_8,
	g12: g12,
	g13_0: g13_0,
	g13_1: g13_1,
	g13_2: g13_2,
	g13_3: g13_3,
	g13_4: g13_4,
	g13_5: g13_5,
	g13_6: g13_6,
	g13_7: g13_7,
	g13: g13,
};

export default gallery;
